<template>
  <div class="potential contractDetail">
    <loading v-if="loadingFlag"></loading>
    <div ref="navBar">
      <rxNavBar title="租约变更详情"></rxNavBar>
    </div>
    <p class="application_time">
        <!--申请时间：-->
        <!--<span>{{type=='2'?contractInfo.contractBeginTime:contractInfo.renterContractBeginTime}}</span>-->
        房源截止时间：
        <span>{{rowData.houseEndTime ? rowData.houseEndTime:'暂无'}}</span>
      </p>
    <div class="panel withoutMargin">
      <!-- 已审批 -->
      <div class="state stateGreen" v-if="rowData.labelText=='已审批'">
        {{rowData.labelText}}
      </div>
      <!-- 申请中 -->
      <div class="state stateOrange" v-if="rowData.labelText=='申请中'">
        {{rowData.labelText}}
      </div>
      <!-- 已提交 已签字 上级已审批 -->
      <div class="state stateRed" v-if="rowData.labelText=='已提交' || rowData.labelText=='已签字' || rowData.labelText=='上级已审批'">
        {{rowData.labelText}}
      </div>
      <!-- 已取消 -->
      <div class="state stateGray" v-if="rowData.labelText=='已取消'">
        {{rowData.labelText}}
      </div>
      <div class="title">
        <p class="van-multi-ellipsis--l2">{{rowData.houseAddress}}</p>
      </div>
      <van-row class="info">
        <van-col span="24">
          <p>
            {{type=='2'?'业主':'租客'}}：
            <span>{{rowData.glouserName}}</span>
          </p>
        </van-col>
      </van-row>
      <div class="info">
        <p>
					<span>房源编号：{{rowData.shhouseCode}}</span>
        </p>
        <p>
          <span>合同编号：{{rowData.contractCode}}</span>
        </p>
      </div>
    </div>
    <div class="tabWrapper">
      <van-tabs v-model="tabActive" line-width="20" line-height="4" :lazy-render="false" title-active-color="rgb(255,81,45) " @click="onClick">
        <van-tab :title="rowData.typeVal">
          <span class="contractOrLeaseChange">解除信息</span>
          <div class="panel refund">
            <div class="info">
              <p>
                是否正常退租：
                <span>{{rowData.isNormalReturn == undefined? '暂无' : rowData.isNormalReturn}}</span>
              </p>
              <p>
                解除日期：
                <span>{{rowData.estimatedDepartureTime == undefined? '暂无' : rowData.estimatedDepartureTime}}</span>
              </p>
              <p>
                违约方：
                <span>{{rowData.defaultingParty == undefined ? '暂无' : rowData.defaultingParty}}</span>
              </p>
              <p>
                是否有责：
                <span>{{rowData.defaultingType == undefined? '暂无' : rowData.defaultingType}}</span>
              </p>
              <p v-if="rowData.billFlag != undefined">
                是否预生成账单：
                <span>{{rowData.billFlag == undefined? '暂无' : rowData.billFlag==='0'?'是':'否'}}</span>
              </p>
              <p>
                解除类型：
                <span>{{rowData.contractTerminationTypeStr== undefined? '暂无' : rowData.contractTerminationTypeStr}}</span>
              </p>
              <p>
                解除原因：
                <span>{{rowData.contractTerminationReasonStr == undefined? '暂无' : rowData.contractTerminationReasonStr}}</span>
              </p>
              <p>
                实际离房时间：
                <span>{{rowData.actualDepartureTime == undefined? '暂无' : rowData.actualDepartureTime}}</span>
              </p>
              <p v-if="rowData.overdueDays">
                逾期天数：
                <span>{{rowData.overdueDays == undefined? '暂无' : rowData.overdueDays}}</span>
              </p>
            </div>
          </div>
          <span class="contractOrLeaseChange">退款详情</span>
          <div class="panel refund">
            <div class="info" v-if="type==1">
              <p>
                剩余租金：
                <span>{{rowData.restRentMoney == undefined? '暂无' : rowData.restRentMoney+'元'}}</span>
              </p>
              <p>
                剩余水电：
                <span>{{rowData.restWaterElectric == undefined? '暂无' : rowData.restWaterElectric+'元'}}</span>
              </p>
              <p>
                收(支)违约金：
                <span>{{rowData.falsify == undefined? '暂无' : rowData.falsify+'元'}}</span>
              </p>
              <p>
                租后赔偿：
                <span>{{rowData.afterrentCompensation == undefined? '暂无' : rowData.afterrentCompensation+'元'}}</span>
              </p>
              <p>
                装修赔偿：
                <span>{{rowData.payRepair == undefined? '暂无' : rowData.payRepair+'元'}}</span>
              </p>
              <p>
                预留水电费：
                <span>{{rowData.reserveWaterElectric == undefined? '暂无' : rowData.reserveWaterElectric+'元'}}</span>
              </p>
              <p>
                应退总额：
                <span>{{rowData.totalRefunded?rowData.totalRefunded+'元':'暂无'}}</span>
              </p>

              <p>
                是否补款{{rowData.isUserPayed?rowData.isUserPayed=='0'?'(无需补款)':'(需补款)':'(无需补款)'}}：
                <!--								<span>{{rowData.isUserPayed=='0'?'暂无':rowData.UserPayedMoney?rowData.UserPayedMoney+'元':'暂无'}}</span>-->
                <span>{{rowData.UserPayedMoney == undefined? '暂无' : rowData.UserPayedMoney+'元'}}</span>
              </p>
              <p>
                实际违约金：
                <span>{{rowData.realFalsify == undefined? '暂无' : rowData.realFalsify+'元'}}</span>
              </p>
              <p>
                滞纳金：
                <span>{{rowData.lateFee== undefined? '暂无' : rowData.lateFee+'元'}}</span>
              </p>
              <p>
                利息：
                <span>{{rowData.interest == undefined? '暂无' : rowData.interest+'元'}}</span>
              </p>
              <p>
                剩余押金：
                <span>{{rowData.surplusRent == undefined? '暂无' : rowData.surplusRent+'元'}}</span>
              </p>
              <p>
                剩余水费：
                <span>{{rowData.restWater == undefined? '暂无' : rowData.restWater+'元'}}</span>
              </p>
              <p>
                服务费：
                <span>{{rowData.serviceCharge == undefined? '暂无' : rowData.serviceCharge+'元'}}</span>
              </p>
              <p>
                剩余物品押金：
                <span>{{rowData.surplusgoodsPrice == undefined? '暂无' : rowData.surplusgoodsPrice+'元'}}</span>
              </p>
            </div>
            <div class="info" v-if="type==2">
              <p>
                业主房租：
                <span>{{rowData.restRentMoney ==undefined? '暂无' : rowData.restRentMoney+'元'}}</span>
              </p>
              <p>
                赔偿租客：
                <span>{{rowData.surplusRent ==undefined? '暂无' : rowData.surplusRent+'元'}}</span>
              </p>
              <p>
                违约金：
                <span>{{rowData.realFalsify  == undefined? '暂无' : rowData.realFalsify+'元'}}</span>
              </p>
              <p>
                水电费：
                <span>{{rowData.waterPowerPayFor  == undefined? '暂无' : rowData.waterPowerPayFor+'元'}}</span>
              </p>
              <p>
                租后赔偿：
                <span>{{rowData.afterrentCompensation  == undefined? '暂无' : rowData.afterrentCompensation+'元'}}</span>
              </p>
              <p>
                装修赔偿：
                <span>{{rowData.payRepair  == undefined? '暂无' : rowData.payRepair+'元'}}</span>
              </p>
              <p>
                公司退回总额：
                <span>{{rowData.userTotalPay == undefined? '暂无' : rowData.userTotalPay+'元'}}</span>
              </p>
              <p>
                业主退回总额：
                <span>{{rowData.realBreakMoney == undefined? '暂无' : rowData.realBreakMoney+'元'}}</span>
              </p>
              <p>
                装修折旧：
                <span>{{rowData.decorationDepreciation  ==undefined ? '暂无' : rowData.decorationDepreciation+'元'}}</span>
              </p>
            </div>
            <div class="info">
              <p>补充说明：</p>
              <p>
                <span>{{rowData.remarks}}</span>
              </p>
            </div>
          </div>
          <div class="panel refund">
            <div class="info">
              <p>
                变更时间：
                <span>{{rowData.chAddTime == undefined? '暂无' : rowData.chAddTime}}</span>
              </p>
              <p>
                变更人：
                <span>{{rowData.addStaffName == undefined? '暂无' : rowData.addStaffName}}</span>
              </p>
              <p>
                维护人：
                <span>{{rowData.maintainStaffName == undefined? '暂无' : rowData.maintainStaffName}}</span>
              </p>
            </div>
          </div>
        </van-tab>

        <van-tab :title="type=='2'?'业主':'租客'">
          <div>
            <span class="contractOrLeaseChange">{{type=='1'?'租客信息':'业主信息'}}</span>
            <div class="panel">
              <van-row type="flex" align="center" class="info">
                <van-col span="5">
                  <van-image round width="50" height="50" fit="contain" style="background-color: #e1eaec;" :src="rowData.renterIconPath?rowData.renterIconPath:userDefaultHeadImg" />
                </van-col>
                <van-col>
                  <p class="tenant">
                    <span>{{rowData.glouserName}}</span>
                  </p>
                </van-col>
              </van-row>
              <div class="info">
                <p>
                  开户姓名：
                  <span>{{rowData.rentuserBankName}}</span>
                </p>
                <p>
                  开户行：
                  <span>{{rowData.rentuserBank?rowData.rentuserBank:'暂无'}}</span>
                </p>
                <p>
                  银行：
                  <span>{{rowData.rentuserBankCode?rowData.rentuserBankCode:'暂无'}}</span>
                </p>
                <p>
                  支行：
                  <span>{{rowData.rentuserSubBank?rowData.rentuserSubBank:'暂无'}}</span>
                </p>
                <p>
                  省份：
                  <span>{{rowData.province?rowData.province:'暂无'}}</span>
                </p>
                <p>
                  城市：
                  <span>{{rowData.city?rowData.city:'暂无'}}</span>
                </p>
              </div>
            </div>
          </div>
        </van-tab>

        <!-- 租客-->
        <van-tab v-if="type=='1'" title="合同">
          <span class="contractOrLeaseChange">原合同</span>
          <div class="panel refund">
            <van-row class="info">
              <van-col span="24">
                <p>
                  租期:
                  <span>{{oldContractInfo.contractBeginTime}}</span>
                  <span class="hasPadding"> 至 </span>
                  <span>{{oldContractInfo.contractEndTime}}</span>
                </p>
              </van-col>
              <van-col span="24">
                <p>
                  合同编号：
                  <span class="total_color">{{oldContractInfo.contractCode}}</span>
                </p>
              </van-col>
              <van-col span="12">
                <p>
                  租金：
                  <span>{{oldContractInfo.rentMoney}}元/月</span>
                </p>
              </van-col>
              <van-col span="12">
                <p>
                  付款方式:
                  <span style="margin-left: 8px;">{{oldContractInfo.payTypeName}}</span>
                </p>
              </van-col>
              <van-col span="12">
                <p>
                  押金：
                  <span>{{oldContractInfo.depositMoney}}元/月</span>
                </p>
              </van-col>
              <van-col span="12">
                <p>
                  物品押金：
                  <span>{{oldContractInfo.goodsPrice?oldContractInfo.goodsPrice:0}}元/月</span>
                </p>
              </van-col>

            </van-row>
            <div class="info">
              <p class="total_color">
                <span>签约人：<span class="total_color hasPadding"></span>{{oldContractInfo.sinName}}</span>
                <span class="hasPadding">|</span>
                <span>{{oldContractInfo.sinDepartmentName}}</span>
                <span class="floatR small_font">{{oldContractInfo.applicationTime}}</span>
              </p>
              <p class="total_color" v-if="oldContractInfo.partnerStaff_id">
								<span>合作人：<span class="total_color hasPadding"></span>{{oldContractInfo.parUserName}}<span class="hasPadding">|</span>{{oldContractInfo.cooperationDepartmentName}}
								</span>
              </p>
              <p v-if="oldContractInfo.partnerStaff_id"><span>合作占比：{{oldContractInfo.pardicDescription}}</span></p>
            </div>
          </div>
          <div class="panel" v-if="oldenclosurePropertyPicList.length>0">
            合同附件
            <div class="swiper_show" ref="wrapperBox1">
              <div class="content">
                <div class="imgWrap" v-for="(url,i) in oldenclosurePropertyPicList" :key="i" @click="oldenclosureviewImg(i)">
                  <van-image width="100" style="background-color: #e1eaec;" height="75" fit="contain" radius="10" :src="url" />
                </div>
              </div>
              <div class="num">{{oldenclosurePropertyPicList.length}}图</div>
            </div>
          </div>
          <div v-if="rowData.newrenterContractId!=undefined">
            <span class="contractOrLeaseChange">新合同</span>
            <div class="panel refund">
              <van-row class="info">
                <van-col span="24">
                  <p>
                    租期:
                    <span>{{newContractInfo.contractBeginTime}}</span>
                    <span class="hasPadding"> 至 </span>
                    <span>{{newContractInfo.contractEndTime}}</span>
                  </p>
                </van-col>
                <van-col span="24">
                  <p>
                    合同编号：
                    <span class="total_color">{{newContractInfo.contractCode}}</span>
                  </p>
                </van-col>
                <van-col span="12">
                  <p>
                    租金：
                    <span>{{newContractInfo.rentMoney}}元/月</span>
                  </p>
                </van-col>
                <van-col span="12">
                  <p>
                    付款方式:
                    <span style="margin-left: 8px;">{{newContractInfo.payTypeName}}</span>
                  </p>
                </van-col>
                <van-col span="12">
                  <p>
                    押金：
                    <span>{{newContractInfo.depositMoney}}元/月</span>
                  </p>
                </van-col>
                <van-col span="12">
                  <p>
                    物品押金：
                    <span>{{newContractInfo.goodsPrice?newContractInfo.goodsPrice:0}}元/月</span>
                  </p>
                </van-col>

              </van-row>
              <div class="info">
                <p class="total_color">
                  <span>签约人：<span class="total_color hasPadding"></span>{{newContractInfo.sinName}}</span>
                  <span class="hasPadding">|</span>
                  <span>{{newContractInfo.sinDepartmentName}}</span>
                  <span class="floatR small_font">{{newContractInfo.applicationTime}}</span>
                </p>
                <p class="total_color" v-if="newContractInfo.partnerStaff_id">
								<span>合作人：<span class="total_color hasPadding"></span>{{newContractInfo.parUserName}}<span class="hasPadding">|</span>{{newContractInfo.cooperationDepartmentName}}
								</span>
                </p>
                <p v-if="newContractInfo.partnerStaff_id"><span>合作占比：{{newContractInfo.pardicDescription}}</span></p>
              </div>
            </div>
            <div class="panel" v-if="newenclosurePropertyPicList.length>0">
              合同附件
              <div class="swiper_show" ref="wrapperBox1">
                <div class="content">
                  <div class="imgWrap" v-for="(url,i) in newenclosurePropertyPicList" :key="i" @click="newenclosureviewImg(i)">
                    <van-image width="100" style="background-color: #e1eaec;" height="75" fit="contain" radius="10" :src="url" />
                  </div>
                </div>
                <div class="num">{{newenclosurePropertyPicList.length}}图</div>
              </div>
            </div>
          </div>
        </van-tab>
        <!-- 业主-->
        <van-tab v-if="type=='2'" title="合同">
          <span class="contractOrLeaseChange">原合同</span>
          <div class="panel refund">
            <van-row class="info">
              <van-col span="24">
                <p>
                  产权地址:
                  <span>{{oldOwnerContractInfo.propertyRightAddress}}</span>
                </p>
              </van-col>
              <van-col span="24">
                <p>
                  交房日期:
                  <span>{{oldOwnerContractInfo.giveHouseTime}}</span>
                </p>
              </van-col>
              <van-col span="24">
                <p>
                  合同期限:
                  <span>{{oldOwnerContractInfo.contractBeginTime}}</span>
                  <span class="hasPadding">至</span>
                  <span>{{oldOwnerContractInfo.contractEndTime}}</span>
                </p>
              </van-col>
              <van-col span="24">
                <p>
                  装修期限:
                  <span>{{oldOwnerContractInfo.renovationBeginTime}}</span>
                  <span class="hasPadding">至</span>
                  <span>{{oldOwnerContractInfo.renovationEndTime}}</span>
                </p>
              </van-col>
              <van-col span="24">
                <p>
                  合同编号:
                  <span class="total_color">{{oldOwnerContractInfo.contractCode}}</span>
                </p>
              </van-col>
              <van-col span="12">
                <p>
                  免租期类型:
                  <span>{{oldOwnerContractInfo.noFeeTypeVal}}</span>
                </p>
              </van-col>
              <van-col span="12">
                <p>
                  租金:
                  <span>{{oldOwnerContractInfo.rentMoney}}元/月</span>
                </p>
              </van-col>
              <van-col span="12">
                <p>
                  付款方式:
                  <span>{{oldOwnerContractInfo.payTypeName}}</span>
                </p>
              </van-col>

              <van-col span="12">
                <p>
                  合同年限:
                  <span>{{oldOwnerContractInfo.contractYears}}年</span>
                </p>
              </van-col>

              <van-col span="24" v-if="oldOwnerContractInfo.maintenanceFundAmount>0">
                <p>
                  维修基金金额:
                  <span>{{oldOwnerContractInfo.maintenanceFundAmount}}元/年</span>
                </p>
              </van-col>

              <van-col span="12">
                <p>
                  权属类型:
                  <span>{{oldOwnerContractInfo.propertyRightTypeVal}}</span>
                </p>
              </van-col>
              <van-col span="24">
                <p>
                  产权证号:
                  <span>{{oldOwnerContractInfo.propertyRightCode}}</span>
                </p>
              </van-col>
              <van-col span="12">
                <p>
                  面积:
                  <span>{{oldOwnerContractInfo.builtUpArea}}㎡</span>
                </p>
              </van-col>

              <van-col span="12">
                <p>
                  朝向:
                  <span>{{oldOwnerContractInfo.orientationVal}}</span>
                </p>
              </van-col>
              <van-col span="12">
                <p>
                  供暖情况:
                  <span>{{oldOwnerContractInfo.heatingModeVal?oldOwnerContractInfo.heatingModeVal:'暂无'}}</span>
                </p>
              </van-col>
              <van-col span="12">
                <p>
                  抵押情况:
                  <span>{{oldOwnerContractInfo.mortgageSituationVal?oldOwnerContractInfo.mortgageSituationVal:'暂无'}}</span>
                </p>
              </van-col>

            </van-row>
            <div class="info">
              <p class="total_color">
                <span>签约人：<span class="total_color hasPadding"></span>{{oldOwnerContractInfo.sinName}}</span>
                <span class="hasPadding">|</span>
                <span>{{oldOwnerContractInfo.sinDepartmentName}}</span>
                <span class="floatR small_font">{{oldOwnerContractInfo.applicationTime}}</span>
              </p>
              <!--             partnerDepartmentName -->
              <p class="total_color" v-if="oldOwnerContractInfo.partnerStaff_id">
								<span>合作人：<span class="total_color hasPadding"></span>{{oldOwnerContractInfo.parUserName}}<span class="hasPadding">|</span>{{contractInfo.cooperationDepartmentName}}
								</span>
              </p>
              <p v-if="oldOwnerContractInfo.partnerStaff_id"><span>合作占比：{{oldOwnerContractInfo.pardicDescription}}</span></p>
            </div>
          </div>
          <div class="panel" v-if="oldOwnerEnclosurePropertyPicList.length>0">
            产权证明
            <div class="swiper_show" ref="wrapperBox1">
              <div class="content">
                <div class="imgWrap" v-for="(url,i) in oldOwnerEnclosurePropertyPicList" :key="i" @click="oldOwnerEnclosureviewImg(i)">
                  <van-image width="100" style="background-color: #e1eaec;" height="75" fit="contain" radius="10" :src="url" />
                </div>
              </div>
              <div class="num">{{oldOwnerEnclosurePropertyPicList.length}}图</div>
            </div>
          </div>
          <div class="panel" v-if="oldOutLinecontratPicList.length>0">
            线下合同
            <div class="swiper_show" ref="wrapperBox2">
              <div class="content">
                <div class="imgWrap" v-for="(url,i) in oldOutLinecontratPicList" :key="i" @click="oldOutLinecontratviewImg(i)">
                  <van-image width="100" height="75" fit="contain" style="background-color: #e1eaec;" radius="10" :src="url" />
                </div>
              </div>
              <div class="num">{{oldOutLinecontratPicList.length}}图</div>
            </div>
          </div>
          <div class="noFreepanel" v-if="oldnoFreeList.length>0">
            <div class="freeTime" v-for="(item,index) in oldnoFreeList" :key="index">
              <div class="freeOne">
                <div class="freeOne-title">第{{index+1}}年<span>: {{item.noFeeMonthLong}}月{{item.noFeeTimeLong}}天</span></div>
                <div class="freeOne-time">免租期限:<span> {{timestampToTime(item.noFeeBeginTime)}} 至
										{{timestampToTime(item.noFeeEndTime)}}</span></div>
                <div class="freeOne-time">委托期限:<span> {{timestampToTime(item.everyYearEntrustBeginTime)}} 至
										{{timestampToTime(item.everyYearEntrustEndTime)}}</span></div>
              </div>
            </div>
            <div class="totalTime">
              <div class="totalTitle">总免租期</div>
              <div class="totalLine"></div>
              <div class="totalDays">{{getTotalNoFreeMonth()}}月{{getTotalNoFreeDays()}}天</div>
              <div class="totalDesc"></div>
            </div>
          </div>
          <div v-if="rowData.newlContract_id!=undefined">
            <span class="contractOrLeaseChange">新合同</span>
            <div class="panel refund">
              <van-row class="info">
                <van-col span="24">
                  <p>
                    产权地址:
                    <span>{{newOwnerContractInfo.propertyRightAddress}}</span>
                  </p>
                </van-col>
                <van-col span="24">
                  <p>
                    交房日期:
                    <span>{{newOwnerContractInfo.giveHouseTime}}</span>
                  </p>
                </van-col>
                <van-col span="24">
                  <p>
                    合同期限:
                    <span>{{newOwnerContractInfo.contractBeginTime}}</span>
                    <span class="hasPadding">至</span>
                    <span>{{newOwnerContractInfo.contractEndTime}}</span>
                  </p>
                </van-col>
                <van-col span="24">
                  <p>
                    装修期限:
                    <span>{{newOwnerContractInfo.renovationBeginTime}}</span>
                    <span class="hasPadding">至</span>
                    <span>{{newOwnerContractInfo.renovationEndTime}}</span>
                  </p>
                </van-col>
                <van-col span="24">
                  <p>
                    合同编号:
                    <span class="total_color">{{newOwnerContractInfo.contractCode}}</span>
                  </p>
                </van-col>
                <van-col span="12">
                  <p>
                    免租期类型:
                    <span>{{newOwnerContractInfo.noFeeTypeVal}}</span>
                  </p>
                </van-col>
                <van-col span="12">
                  <p>
                    租金:
                    <span>{{newOwnerContractInfo.rentMoney}}元/月</span>
                  </p>
                </van-col>
                <van-col span="12">
                  <p>
                    付款方式:
                    <span>{{newOwnerContractInfo.payTypeName}}</span>
                  </p>
                </van-col>

                <van-col span="12">
                  <p>
                    合同年限:
                    <span>{{newOwnerContractInfo.contractYears}}年</span>
                  </p>
                </van-col>

                <van-col span="24" v-if="newOwnerContractInfo.maintenanceFundAmount>0">
                  <p>
                    维修基金金额:
                    <span>{{newOwnerContractInfo.maintenanceFundAmount}}元/年</span>
                  </p>
                </van-col>

                <van-col span="12">
                  <p>
                    权属类型:
                    <span>{{newOwnerContractInfo.propertyRightTypeVal}}</span>
                  </p>
                </van-col>
                <van-col span="24">
                  <p>
                    产权证号:
                    <span>{{newOwnerContractInfo.propertyRightCode}}</span>
                  </p>
                </van-col>
                <van-col span="12">
                  <p>
                    面积:
                    <span>{{newOwnerContractInfo.builtUpArea}}㎡</span>
                  </p>
                </van-col>

                <van-col span="12">
                  <p>
                    朝向:
                    <span>{{newOwnerContractInfo.orientationVal}}</span>
                  </p>
                </van-col>
                <van-col span="12">
                  <p>
                    供暖情况:
                    <span>{{newOwnerContractInfo.heatingModeVal?newOwnerContractInfo.heatingModeVal:'暂无'}}</span>
                  </p>
                </van-col>
                <van-col span="12">
                  <p>
                    抵押情况:
                    <span>{{newOwnerContractInfo.mortgageSituationVal?newOwnerContractInfo.mortgageSituationVal:'暂无'}}</span>
                  </p>
                </van-col>

              </van-row>
              <div class="info">
                <p class="total_color">
                  <span>签约人：<span class="total_color hasPadding"></span>{{newOwnerContractInfo.sinName}}</span>
                  <span class="hasPadding">|</span>
                  <span>{{newOwnerContractInfo.sinDepartmentName}}</span>
                  <span class="floatR small_font">{{newOwnerContractInfo.applicationTime}}</span>
                </p>
                <!--             partnerDepartmentName -->
                <p class="total_color" v-if="newOwnerContractInfo.partnerStaff_id">
								<span>合作人：<span class="total_color hasPadding"></span>{{newOwnerContractInfo.parUserName}}<span class="hasPadding">|</span>{{contractInfo.cooperationDepartmentName}}
								</span>
                </p>
                <p v-if="newOwnerContractInfo.partnerStaff_id"><span>合作占比：{{newOwnerContractInfo.pardicDescription}}</span></p>
              </div>
            </div>
            <div class="panel" v-if="newOwnerEnclosurePropertyPicList.length>0">
              产权证明
              <div class="swiper_show" ref="wrapperBox1">
                <div class="content">
                  <div class="imgWrap" v-for="(url,i) in newOwnerEnclosurePropertyPicList" :key="i" @click="newOwnerEnclosureviewImg(i)">
                    <van-image width="100" style="background-color: #e1eaec;" height="75" fit="contain" radius="10" :src="url" />
                  </div>
                </div>
                <div class="num">{{newOwnerEnclosurePropertyPicList.length}}图</div>
              </div>
            </div>
          </div>
          <div class="panel" v-if="newOutLinecontratPicList.length>0">
            线下合同
            <div class="swiper_show" ref="wrapperBox2">
              <div class="content">
                <div class="imgWrap" v-for="(url,i) in newOutLinecontratPicList" :key="i" @click="newOutLinecontratviewImg(i)">
                  <van-image width="100" height="75" fit="contain" style="background-color: #e1eaec;" radius="10" :src="url" />
                </div>
              </div>
              <div class="num">{{newOutLinecontratPicList.length}}图</div>
            </div>
          </div>
          <div class="noFreepanel" v-if="newnoFreeList.length>0">
            <div class="freeTime" v-for="(item,index) in newnoFreeList" :key="index">
              <div class="freeOne">
                <div class="freeOne-title">第{{index+1}}年<span>: {{item.noFeeMonthLong}}月{{item.noFeeTimeLong}}天</span></div>
                <div class="freeOne-time">免租期限:<span> {{timestampToTime(item.noFeeBeginTime)}} 至
										{{timestampToTime(item.noFeeEndTime)}}</span></div>
                <div class="freeOne-time">委托期限:<span> {{timestampToTime(item.everyYearEntrustBeginTime)}} 至
										{{timestampToTime(item.everyYearEntrustEndTime)}}</span></div>
              </div>
            </div>
            <div class="totalTime">
              <div class="totalTitle">总免租期</div>
              <div class="totalLine"></div>
              <div class="totalDays">{{getTotalNoFreeMonth()}}月{{getTotalNoFreeDays()}}天</div>
              <div class="totalDesc"></div>
            </div>
          </div>
        </van-tab>


        <van-tab title="交割">
          <!--  租约变更的  -->
          <div class="aa" v-if="JSON.stringify(tenantContractProperty) != '{}'">
            <span class="contractOrLeaseChange">解除物业交割</span>
            <!-- 表-->
            <div class="panel">
              <div class="info" v-if="tenantContractProperty.wattMeterNumber">
                <p>电表</p>
                <p>
                  <span>{{tenantContractProperty.wattMeterCodeTypeStr}}：</span>
                  <span>{{tenantContractProperty.wattMeterCode?tenantContractProperty.wattMeterCode:'暂无'}}</span>
                  <span class="hasPadding">/</span>
                  <span>底表数：</span>
                  <span>{{tenantContractProperty.wattMeterNumber}}</span>
                </p>
                <p>
                  <span>备注：</span>
                  <span>{{tenantContractProperty.wattMeterRemarks}}</span>
                </p>
              </div>
              <div class="info" v-if="tenantContractProperty.waterMeterNumber">
                <p>水表</p>
                <p>
                  <span>{{tenantContractProperty.waterMeterCodeTypeStr}}：</span>
                  <span>{{tenantContractProperty.waterMeterCode?tenantContractProperty.waterMeterCode:'暂无'}}</span>
                  <span class="hasPadding">/</span>
                  <span>底表数：</span>
                  <span>{{tenantContractProperty.waterMeterNumber}}</span>
                </p>
                <p>
                  <span>备注：</span>
                  <span>{{tenantContractProperty.waterMeterRemarks}}</span>
                </p>
              </div>
              <div class="info" v-if="tenantContractProperty.hotWaterMeterCode">
                <p>热水表</p>
                <p>
                  <span>{{tenantContractProperty.hotWaterMeterCodeTypeStr}}：</span>
                  <span>{{tenantContractProperty.hotWaterMeterCode?tenantContractProperty.hotWaterMeterCode:'暂无'}}</span>
                  <span class="hasPadding">/</span>
                  <span>底表数：</span>
                  <span>{{tenantContractProperty.hotWaterMeterNumber}}</span>
                </p>
                <p>
                  <span>备注：</span>
                  <span>{{tenantContractProperty.hotWaterMeterRemarks}}</span>
                </p>
              </div>
              <div class="info" v-if="tenantContractProperty.gasMeterNumber">
                <p>燃气表</p>
                <p>
                  <span>{{tenantContractProperty.gasMeterCodeTypeStr}}：</span>
                  <span>{{tenantContractProperty.gasMeterCode?tenantContractProperty.gasMeterCode:'暂无'}}</span>
                  <span class="hasPadding">/</span>
                  <span>底表数：</span>
                  <span>{{tenantContractProperty.gasMeterNumber}}</span>
                </p>
                <p>
                  <span>备注：</span>
                  <span>{{tenantContractProperty.gasMeterRemarks}}</span>
                </p>
              </div>
            </div>
            <div class="panel" v-if="tenantContractProperty.description">
              <div class="bz">交割备注:&nbsp;</div><span class="span-description">{{tenantContractProperty.description}}</span>
            </div>
            <!-- 解除检查项 -->
            <div>
              <div class="panel" v-if="relieveRoomPricingTagDetail.length>0">
                <div class="info" v-for="(item,index) in relieveRoomPricingTagDetail" :key="index">
                  <p>
                    房间 -
                    {{item.pricingTagName}} -
                    {{item.itemCount==1?'有':'无'}} -
                    {{item.dictionaryTitle}}
                    <span class="DO_link" @click="showPropertyDetailPhotos(item)">查看实物图></span>
                  </p>
                  <p>
                    <span>描述：</span>
                    <span>{{item.itemDescription}}</span>
                  </p>
                </div>
              </div>
            </div>
            <!-- 解除公区检查项 -->
            <div>
              <div class="panel" v-if="relievePublicPricingTagDetail.length>0">
                <div class="info" v-for="(item,index) in relievePublicPricingTagDetail" :key="index">
                  <p>
                    公区 -
                    {{item.pricingTagName}} -
                    {{item.itemCount==1?'有':'无'}} -
                    {{item.dictionaryTitle}}
                    <span class="DO_link" @click="showPropertyDetailPhotos(item)">查看实物图></span>
                  </p>
                  <p>
                    <span>描述：</span>
                    <span>{{item.itemDescription}}</span>
                  </p>
                </div>
              </div>
            </div>
            <!-- 软装 -->
            <div>
              <div class="panel" v-if="tenantPropertyDetail.length>0">
                <div class="info" v-for="(item,index) in tenantPropertyDetail" :key="index">
                  <p>
                    {{item.name}}
                    <span class="DO_link" @click="showLeasePropertyDetailPhotos(item)">查看实物图></span>
                  </p>
                  <p>
                    <span>描述：</span>
                    <span>{{item.sublist[0].itemDescription}}</span>
                  </p>
                </div>
              </div>
            </div>
            <!-- 操作信息-->
            <div class="panel refund">
              <div class="info">
                <p>
                  交割人：
                  <span>{{tenantContractProperty.staffName == undefined? '暂无' : tenantContractProperty.staffName}}</span>
                </p>
                <p>
                  交割时间：
                  <span>{{tenantContractProperty.addTime == undefined? '暂无' : tenantContractProperty.addTime}}</span>
                </p>
              </div>
            </div>
            <!--检查项弹出层-->
            <van-popup v-model="show" position="bottom" :style="{ height: '30%' }" >
              <div class="swiper_show" ref="wrapperBox">
                <div class="content">
                  <div class="imgWrap" v-for="(url,i) in propertyDetailPhotos" :key="i" @click="previewPropertyDetailPhotos(i)">
                    <van-image width="100" height="75" fit="contain" radius="10" :src="url" />
                  </div>
                </div>
                <div class="num">{{propertyDetailPhotos.length}}图</div>
              </div>
            </van-popup>
            <!--配置物品弹出层-->
            <van-popup v-model="showLease" position="bottom" :style="{ height: '30%' }">
              <div class="swiper_show" ref="wrapperBox">
                <div class="content">
                  <div class="imgWrap" v-for="(url,i) in leasePropertyDetailPhotos" :key="i" @click="previewLeasePropertyDetailPhotos(i)">
                    <van-image width="100" height="75" fit="contain" radius="10" :src="url" />
                  </div>
                </div>
                <div class="num">{{leasePropertyDetailPhotos.length}}图</div>
              </div>
            </van-popup>
          </div>
          <!--空状态图-->
          <common-empty v-else></common-empty>
        </van-tab>

        <van-tab title="账单">
          <div class="bill" v-if="billList.length>0">
            <div v-for="(item,i) in billList" :key="i">
              <div class="billPayTime">{{item.billFeeType_id == '2' && (item.billStatus == 2 || item.billStatus == 3) ? item.billEndTime : item.billPayTime}}</div>
              <div class="bill_panel">
                <van-row class="bill_info" type="flex" align="center">
                  <!-- 四种状态 待确认billStatus 账单状态(0 应收=应支 1已收=已支 2 应付=应收 3 已付=已收 4 停付) 作为业主 对应的是海米  应该支付给海米（应支） 134 金额浅色-->
                  <div class="laber " v-if="item.billStatus==0">
                    <div class="laberText  ">应收</div>
                  </div>
                  <div class="laber " v-if="item.billStatus==1">
                    <div class="laberText ">已收</div>
                  </div>
                  <div class="laber " v-if="item.billStatus==2">
                    <div class="laberText ">应支</div>
                  </div>
                  <div class="laber " v-if="item.billStatus==3">
                    <div class="laberText ">已支</div>
                  </div>
                  <div class="laber " v-if="item.billStatus==4">
                    <div class="laberText ">停付</div>
                  </div>
                  <van-col span="19">
                    <p>
                      {{item.dictionaryTitle}}
                      <span>{{item.billDescription ? '(' + item.billDescription + ')' : ''}}</span>
                    </p>
                    <p class="small">
                      <span>费用周期：</span>
                      <span>{{item.billBeginTime}}</span>
                      <span class="hasPadding">至</span>
                      <span>{{item.billEndTime}}</span>
                    </p>
                  </van-col>

                  <van-col span="5">
                    <p :class="item.billStatus=='1' || item.billStatus=='3' || item.billStatus=='4' ? 'rentPriceA':' total_color bill_money'">
                      {{item.totalAmount}}元
                    </p>
                  </van-col>
                </van-row>
              </div>
            </div>
          </div>
          <common-empty v-else></common-empty>
        </van-tab>

        <van-tab title="评论">
          <div class="approve" v-if="approvalList.length>0&&approvalList[0].addTime!=undefined">
            <div class="time_line">
              <div class="item" v-for="(item,index) in approvalList" :key="index">
                <div class="left">
                  <div :class="{line:true ,line_top:true,first_top:index==0}"></div>
                  <div class="point"></div>
                  <div class="line line_bottom" v-if="index != approvalList.length - 1"></div>
                </div>
                <div class="right">
                  <div class="approve_panel">
                    <p class="total_color">
                      {{item.staffName}}
                      <span style="display: inline-block;margin-left: 0.2rem">{{item.sourceTypeName}}</span>
                      <span class="floatR small_font">{{item.addTime}}</span>
                    </p>
                    <p style="color: #525252">
                      {{item.dictionaryTitle?item.dictionaryTitle:''}}
                    </p>
                    <p>
                      {{item.returnVisitContent}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <common-empty v-else></common-empty>
        </van-tab>

        <van-tab title="审批">
          <div class="approve" v-if="approvalHistroyList.length>0">
            <div class="time_line">
              <div class="item">
                <div class="left">
                  <div :class="{line:true ,line_top:true,first_top:true}"></div>
                  <div class="point"></div>
                  <div class="line line_bottom"></div>
                </div>
                <div class="right">
                  <div class="approve_panel">
                    <p class="total_color">
                      申请
                      <span class="floatR small_font">{{approvalHistroyInfo.applicationtime}}</span>
                    </p>
                    <p>
                      {{approvalHistroyInfo.applicationName}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="item" v-for="(item,index) in approvalHistroyList" :key="index">
                <div class="left">
                  <div :class="{line:true ,line_top:true,first_top:false}"></div>
                  <div class="point"></div>
                  <div class="line line_bottom" v-if="index != approvalHistroyList.length - 1"></div>
                </div>
                <div class="right">
                  <div class="approve_panel">
                    <p class="total_color">
                      {{item.approvalResultStr}}
                      <span v-if="item.dictionaryTitle" style="display: inline-block;margin-left: 0.2rem">{{item.dictionaryTitle}}</span>
                      <span style="display: inline-block;margin-left: 0.2rem">{{item.approvalTypeStr}}</span>
                      <span class="floatR small_font">{{item.addTime}}</span>
                    </p>
                    <p>
                      {{item.approvalStaffName}}
                      <span style="display: inline-block;margin-left: 0.2rem">{{item.rremarks}}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <common-empty v-else></common-empty>
        </van-tab>
      </van-tabs>



    </div>
  </div>
</template>

<script>
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {
  Button,
  Col,
  Dialog,
  Divider,
  Image as VanImage,
  ImagePreview,
  loading,
  NavBar,
  Popup,
  Row,
  Tab,
  Tabs,
  Tag, Toast
} from "vant";
import BScroll from "better-scroll";
import dialMobile from "../../../components/rongxun/rx-dialMobile/dialMobile";
import {
  findContractHistory,
  findContractHistoryDetail,
  findOwnerContractInfo,
  queryContractProperty, queryOwnerContractBill,
  queryRenterContractBill,
  renterContractInfo,
  visitRecord,
} from "../../../getData/getData";
import {getStaffId, responseUtil} from "../../../libs/rongxunUtil";

export default {
  name: "contractChangeDetail",
  components: {
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Row.name]: Row,
    [Col.name]: Col,
    [Tag.name]: Tag,
    [Divider.name]: Divider,
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    [BScroll.name]: BScroll,
    [ImagePreview.name]: ImagePreview,
    [Popup.name]: Popup,
    [loading.name]: loading,
    rxNavBar,
    dialMobile,
    [Dialog.Component.name]: Dialog.Component,
  },
  data(){
    return{
      type:'',
      rowData:{},  //行数据上一个页带出的
      tabActive: 0,
      userDefaultHeadImg: require("../../../assets/images/default-headImg.jpg"),
      loadingFlag:false,
      newContractInfo:{},
      oldContractInfo:{},
      oldenclosurePropertyPicList:[],
      newenclosurePropertyPicList:[],
      oldOwnerContractInfo:{},
      newOwnerContractInfo:{},
      oldOwnerEnclosurePropertyPicList:[],  //产权
      oldOutLinecontratPicList:[],  //线下合同
      newOwnerEnclosurePropertyPicList:[],
      newOutLinecontratPicList:[],  //线下合同
      oldnoFreeList:[],
      newnoFreeList:[],
      tenantContractProperty:{},  //租约变更交割
      tenantPropertyDetail:[],
      relieveRoomPricingTagDetail:[],
      relievePublicPricingTagDetail:[],
      propertyDetailPhotos:[],
      leasePropertyDetailPhotos: [], //租约变更的配置物品图片信息
      showLease:false,
      show:false,
      billList:[],
      approvalHistroyList:[],
      approvalHistroyInfo:{},
      approvalList:[],
    }
  },
  created() {
    this.type = this.$route.query.type
    this.rowData.typeVal = this.$route.query.typeVal
    this.rowData.labelText = this.$route.query.labelText
    this.rowData.houseAddress = this.$route.query.houseAddress
    this.rowData.shhouseCode = this.$route.query.shhouseCode
    this.rowData.contractCode = this.$route.query.contractCode
    this.rowData.glouserName = this.$route.query.glouserName
    this.rowData.houseEndTime = this.$route.query.houseEndTime
    this.rowData.rentuserBankName = this.$route.query.rentuserBankName
    this.rowData.rentuserBank = this.$route.query.rentuserBank
    this.rowData.rentuserBankCode = this.$route.query.rentuserBankCode
    this.rowData.rentuserSubBank = this.$route.query.rentuserSubBank
    this.rowData.province = this.$route.query.province
    this.rowData.city = this.$route.query.city

    this.rowData.isNormalReturn = this.$route.query.isNormalReturn==1?'是':'否'
    this.rowData.estimatedDepartureTime = this.$route.query.estimatedDepartureTime
    // this.rowData.defaultingParty = this.$route.query.defaultingParty
    if(this.$route.query.defaultingParty === ''){
      this.rowData.defaultingParty =  '无';
    }else if(this.$route.query.defaultingParty === '0' ){
      this.rowData.defaultingParty =  '平台'
    }else if(this.$route.query.defaultingParty === '1' ){
      this.rowData.defaultingParty =  '业主'
    }else if(this.$route.query.defaultingParty === '2' ){
      this.rowData.defaultingParty =  '租客'
    }
    this.rowData.defaultingType = this.$route.query.defaultingType==0?'无责违约':'有责违约'
    this.rowData.terminationType = this.$route.query.terminationType
    this.rowData.terminationReason = this.$route.query.terminationReason
    this.rowData.billFlag = this.$route.query.billFlag
    this.rowData.contractTerminationTypeStr = this.$route.query.contractTerminationTypeStr
    this.rowData.contractTerminationReasonStr = this.$route.query.contractTerminationReasonStr
    this.rowData.actualDepartureTime = this.$route.query.actualDepartureTime
    this.rowData.overdueDays = this.$route.query.overdueDays

    this.rowData.restRentMoney = this.$route.query.restRentMoney
    this.rowData.restWaterElectric = this.$route.query.restWaterElectric
    this.rowData.falsify = this.$route.query.falsify
    this.rowData.afterrentCompensation = this.$route.query.afterrentCompensation
    this.rowData.payRepair = this.$route.query.payRepair
    this.rowData.reserveWaterElectric = this.$route.query.reserveWaterElectric
    this.rowData.totalRefunded = this.$route.query.totalRefunded
    this.rowData.isUserPayed = this.$route.query.isUserPayed
    this.rowData.UserPayedMoney = this.$route.query.UserPayedMoney
    this.rowData.realFalsify = this.$route.query.realFalsify
    this.rowData.lateFee = this.$route.query.lateFee
    this.rowData.interest = this.$route.query.interest
    this.rowData.surplusRent = this.$route.query.surplusRent
    this.rowData.restWater = this.$route.query.restWater
    this.rowData.serviceCharge = this.$route.query.serviceCharge
    this.rowData.surplusgoodsPrice = this.$route.query.surplusgoodsPrice
    this.rowData.remarks = this.$route.query.remarks

    this.rowData.waterPowerPayFor = this.$route.query.waterPowerPayFor
    this.rowData.userTotalPay = this.$route.query.userTotalPay
    this.rowData.realBreakMoney = this.$route.query.realBreakMoney
    this.rowData.decorationDepreciation = this.$route.query.decorationDepreciation


    this.rowData.chAddTime = this.$route.query.chAddTime
    this.rowData.addStaffName = this.$route.query.addStaffName
    this.rowData.maintainStaffName = this.$route.query.maintainStaffName

    this.rowData.contractId = this.$route.query.contractId   //原合同id
    this.rowData.renterContractId = this.$route.query.renterContractId  //租客合同原租客合同id
    this.rowData.newrenterContractId = this.$route.query.newrenterContractId  //租客合同新租客合同id
    this.rowData.newownerContractId = this.$route.query.newownerContractId  //业主合同新业主合同id
    this.rowData.newlContract_id = this.$route.query.newlContract_id   //业主合同新合同id
    this.rowData.contracthistoryId = this.$route.query.contracthistoryId


    console.log(this.$route.query)
    console.log(this.rowData)
  },
  methods:{
    onClick(name, title) {
      //console.log(title)
      if (title == '合同') {
        if(this.type == '1'){
          this.queryOldRenterContract()
          if(this.rowData.newrenterContractId!=undefined){
            this.queryNewRenterContract()
          }
        }else{
          this.queryOldOwnerContract()
          if(this.rowData.newlContract_id!=undefined){
            this.queryNewOwnerContract()
          }
        }
      }else if (title == '交割') {
        // this.initContractproperty()
        // this.initContractpropertyAn()
        this.queryContractProperty()
      } else if (title == '账单'){
        this.queryOwnerContractBill()
        // if(this.type == '1'){
        //   this.queryOwnerContractBill()
        // }else if(this.type == '2'){
        //   this.queryOwnerContractBill()
        // }
      }else if (title == '评论') {
        this.visitRecord()
      }else if (title == '审批') {
        this.findContractHistory()
      }
    },
    queryOldRenterContract(){
      let that = this
      let queryData = {}
      queryData.globaluser_id = getStaffId()
      queryData.renterContractId = that.$route.query.renterContractId
      that.loadingFlag=true
      renterContractInfo(queryData).then(function(response) {
        that.loadingFlag = false
        responseUtil.dealResponse(that, response, () => {
          that.oldContractInfo = response.data.data.data
          that.initoldContractInfo(that.oldContractInfo.contractId)
        })
      })
    },
    initoldContractInfo(contractId) {
      let that = this
      let initData = {}
      //let id = that.$route.params.contractId
      initData.ownerContractId = contractId
      findOwnerContractInfo(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          let list = []
          that.oldenclosurePropertyPicList = response.data.data.data.enclosurePropertyPicList || []
          for (let i in that.oldenclosurePropertyPicList) {
            list.push(that.oldenclosurePropertyPicList[i].path)
          }
          that.oldenclosurePropertyPicList = list
        })
      })
    },
    queryNewRenterContract(){
      let that = this
      let queryData = {}
      queryData.globaluser_id = getStaffId()
      queryData.renterContractId = that.$route.query.newrenterContractId
      that.loadingFlag=true
      renterContractInfo(queryData).then(function(response) {
        that.loadingFlag = false
        responseUtil.dealResponse(that, response, () => {
          that.newContractInfo = response.data.data.data
          that.initnewContractInfo(that.newContractInfo.contractId)
        })
      })
    },
    initnewContractInfo(contractId) {
      let that = this
      let initData = {}
      //let id = that.$route.params.contractId
      initData.ownerContractId = contractId
      findOwnerContractInfo(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          let list = []
          that.newenclosurePropertyPicList = response.data.data.data.enclosurePropertyPicList || []
          for (let i in that.newenclosurePropertyPicList) {
            list.push(that.newenclosurePropertyPicList[i].path)
          }
          that.newenclosurePropertyPicList = list
        })
      })
    },
    queryOldOwnerContract(){
      let that = this
      let queryData = {}
      queryData.globaluser_id = getStaffId()
      queryData.ownerContractId = that.$route.query.contractId
      that.loadingFlag=true
      findOwnerContractInfo(queryData).then(function(response) {
        that.loadingFlag = false
        responseUtil.dealResponse(that, response, () => {
          that.oldOwnerContractInfo = response.data.data.data
          that.oldnoFreeList = response.data.data.data.contractnofeeList || []
          let list = []
          that.oldOwnerEnclosurePropertyPicList = response.data.data.data.enclosurePropertyPicList || []
          for (let i in that.oldOwnerEnclosurePropertyPicList) {
            list.push(that.oldOwnerEnclosurePropertyPicList[i].path)
          }
          that.oldOwnerEnclosurePropertyPicList = list
        })
      })
    },
    queryNewOwnerContract(){
      let that = this
      let queryData = {}
      queryData.globaluser_id = getStaffId()
      queryData.ownerContractId = that.$route.query.newlContract_id
      that.loadingFlag=true
      findOwnerContractInfo(queryData).then(function(response) {
        that.loadingFlag = false
        responseUtil.dealResponse(that, response, () => {
          that.newOwnerContractInfo = response.data.data.data
          that.newnoFreeList = response.data.data.data.contractnofeeList || []
          let list = []
          that.newOwnerEnclosurePropertyPicList = response.data.data.data.enclosurePropertyPicList || []
          for (let i in that.newOwnerEnclosurePropertyPicList) {
            list.push(that.newOwnerEnclosurePropertyPicList[i].path)
          }
          that.newOwnerEnclosurePropertyPicList = list
        })
      })
    },

    queryContractProperty(){
      let that = this
      let initData = {}
      initData.contract_id = that.rowData.contractId
      queryContractProperty(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          that.relieveRoomPricingTagDetail=response.data.data.relieveRoomPricingTagDetail
          that.relievePublicPricingTagDetail=response.data.data.relievePublicPricingTagDetail

          if(response.data.data.tenantContractProperty){
            that.tenantContractProperty=response.data.data.tenantContractProperty[0]
            that.tenantPropertyDetail=response.data.data.tenantPropertyDetail
          }else{
            that.tenantContractProperty={}
            that.tenantPropertyDetail=[]
          }
        })
      })
    },
    //点击检查项详情按钮
    showPropertyDetailPhotos(item) {
      if(!item.photoList){
        Toast('暂无实物图')
      }else{
        this.propertyDetailPhotos = []
        //console.log(item.sublist[0].photoList)
        let list = item.photoList
        for (let i = 0; i < list.length; i++) {
          this.propertyDetailPhotos.push(list[i].path)
        }
        this.show = true
      }
    },
    //点击配置物品详情按钮
    showLeasePropertyDetailPhotos(item) {
      //this.propertyDetailPhotos
      if(!item.sublist[0].photoList){
        Toast('暂无实物图')
      }else{
        this.leasePropertyDetailPhotos = []
        //console.log(item.sublist[0].photoList)
        let list = item.sublist[0].photoList
        for (let i = 0; i < list.length; i++) {
          this.leasePropertyDetailPhotos.push(list[i].path)
        }
        this.showLease = true
      }
    },


    //合同账单数据加载
    queryOwnerContractBill() {
      let that = this
      let initData = {}
      initData.contractId = that.rowData.contractId
      initData.contracthistory_id = that.rowData.contracthistoryId
      initData.currentPage = "1"
      initData.numberPage = "200"
      if(that.rowData.labelText!='已审批'){
          initData.isOnlyRetreatBill = true
      }
      queryOwnerContractBill(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data.data)
          that.billList = response.data.data.data || []
        })
      })
    },

    // 评论记录
    visitRecord(){
      let that = this
      let initData = {}
      initData.contractId = that.rowData.contractId
      visitRecord(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data.data)
          that.approvalList = response.data.data.data || []
        })
      })
    },
    //合同审批信息记录初始化
    findContractHistory() {
      let that = this
      let initData = {}
      initData.contractHistory_id = that.rowData.contracthistoryId
      findContractHistory(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data.approvalList)
          that.approvalHistroyList = response.data.data.approvalList || []
          that.approvalHistroyInfo = response.data.data
        })
      })
    },


    //图片放大
    oldenclosureviewImg(start) {
      ImagePreview({
        images: this.oldenclosurePropertyPicList,
        startPosition: start,
        onClose() {
          // do something
        }
      });
    },
    newenclosureviewImg(start) {
      ImagePreview({
        images: this.newenclosurePropertyPicList,
        startPosition: start,
        onClose() {
          // do something
        }
      });
    },
    oldOwnerEnclosureviewImg(start) {
      ImagePreview({
        images: this.oldOwnerEnclosurePropertyPicList,
        startPosition: start,
        onClose() {
          // do something
        }
      });
    },
    newOwnerEnclosureviewImg(start) {
      ImagePreview({
        images: this.newOwnerEnclosurePropertyPicList,
        startPosition: start,
        onClose() {
          // do something
        }
      });
    },
    oldOutLinecontratviewImg(start) {
      ImagePreview({
        images: this.oldOutLinecontratPicList,
        startPosition: start,
        onClose() {
          // do something
        }
      });
    },
    newOutLinecontratviewImg(start) {
      ImagePreview({
        images: this.newOutLinecontratPicList,
        startPosition: start,
        onClose() {
          // do something
        }
      });
    },
    previewPropertyDetailPhotos(start) {
      ImagePreview({
        images: this.propertyDetailPhotos,
        startPosition: start,
        onClose() {
          // do something
        }
      });
    },
    previewLeasePropertyDetailPhotos(start) {
      ImagePreview({
        images: this.leasePropertyDetailPhotos,
        startPosition: start,
        onClose() {
          // do something
        }
      });
    },

    //日期格式化方法
    timestampToTime(timestamp) {
      let date = new Date(timestamp)
      let Y = date.getFullYear() + '-'
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      return Y + M + D
    },
    //计算总免周期的方法
    getTotalNoFreeMonth() {
      let total = 0
      if (this.oldnoFreeList.length > 0) {
        for (let i = 0; i < this.oldnoFreeList.length; i++) {
          total += parseInt(this.oldnoFreeList[i].noFeeMonthLong)
        }
        return total
      }
      return ''
    },
    //计算总免周期的方法
    getTotalNoFreeDays() {
      let total = 0
      if (this.oldnoFreeList.length > 0) {
        for (let i = 0; i < this.oldnoFreeList.length; i++) {
          total += parseInt(this.oldnoFreeList[i].noFeeTimeLong)
        }
        return total
      }
      return ''
    },

    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
  }
}
</script>

<style scoped lang="less">
@redius: 8px;
@spanColor: rgba(102, 102, 102, 1);
*{
  margin: 0;
}
.potential {
  width: 100%;
  background-color: #f8f8f8;
  font-size: 12px;
  box-sizing: border-box;
  overflow: hidden;
  //右上角日期
  .application_time {
    text-align: right;
    width: 345px;
    font-size: 12px;
    color: rgb(136, 136, 136);
    padding: 10px 0 5px;
  }
  //第一个box
  .panel {
    /*width: 345px;*/
    width: 92%;
    margin: 15px auto;
    background-color: rgb(255, 255, 255);
    border-radius: @redius;
    position: relative;
    font-size: 15px;
    text-align: left;
    overflow: hidden;
    padding: 15px;
    box-sizing: border-box;

    &.withoutMargin {
      margin: 3px auto;
    }

    p {
      margin: 6px 0;
      font-weight: bolder;

      span {
        font-weight: normal;
        color: rgb(151, 151, 151);
      }
    }
    //左上角状态
    .state {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 20px;
      font-weight: bold;
      height: 31px;
      line-height: 33px;
      width: 105px;
      display: block;
      background-color: rgb(163, 153, 153);
      border-top-left-radius: 16px;
      border-bottom-right-radius: 16px;
      color: rgb(255, 255, 255);
      text-align: center;
      transform: scale(0.5);
      transform-origin: 0% 0%;
      font-family: sans-serif;
      padding: 0 10px;


      &.stateGreen {
        background-image: linear-gradient(to right, #66CB94, #28D3B0);
      }

      &.stateRed {
        background-image: linear-gradient(to right, #E02020, #FF431B);
      }

      &.stateGray {
        background-image: linear-gradient(to right, #999999, #999999);
      }

      &.stateOrange {
        background-image: linear-gradient(to right, #FFC274, #FF5D3B);
      }
    }

    //地址
    .title {
      font-weight: bolder;
      color: rgb(0, 0, 0);
      font-size: 15px;
      /*padding: 20px 15px 15px;*/
      border-bottom: 1px solid rgb(250, 250, 250);

      span {
        font-weight: normal;
        color: rgba(153, 153, 153);
      }
    }

    .info {
      /*padding: 15px;*/
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid rgb(250, 250, 250);

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
      }

      .tenant {
        font-size: 18px;
        display: flex;
        align-items: center;
        .male {
          font-size: 12px;
          color: rgba(86, 155, 236, 1);
          margin-left: 10px;
        }

        .female {
          font-size: 12px;
          color: rgba(255, 140, 219, 1);
          margin-left: 10px;
        }
      }

      .DO_link {
        float: right;
        color: rgba(86, 155, 236, 1);
        cursor: pointer;
        font-size: 12px;
      }
    }

    &.refund {
      p {
        line-height: 24px;
        padding: 0;
        margin: 0;
      }
    }
  }

  /*交割单标题文字*/
  .contractOrLeaseChange {
    display: inline-block;
    color: red;
    font-weight: bold;
    margin: 15px 0 0 15px;
    font-size: 15px;
  }
  .bill {
    box-sizing: border-box;
    padding: 0.25rem;
    //margin-top: 10px;

    .bill_title {
      font-size: 12px;
      background-color: rgb(241, 241, 241);
      color: rgb(155, 155, 155);
      padding: 1px 15px;
    }

    .bill_panel {
      background-color: rgb(255, 255, 255);


      .bill_info {
        position: relative;

        /*padding: 15px;*/
        padding: 0 15px;
        border-bottom: 1px solid rgb(250, 250, 250);

        .img_icon {
          position: absolute;
          top: 0;
          right: -6px;
        }

        &:nth-last-child {
          border-bottom: none;
        }

        .bill_money {
          font-size: 15px;

          &.unreceived {
            opacity: .3;
          }
        }

        p {
          font-weight: bolder;
          font-size: 14px;
          padding-top: 11px;

          &:last-of-type {
            padding-bottom: 10px;
          }
        }

        span {
          font-weight: normal;
          color: rgb(151, 151, 151);
        }
      }
    }
  }
  .billPayTime{
    color: rgb(151, 151, 151);
    height: 18px;
    line-height: 18px;
  }
  /* 未收图标 */
  .laber {
    width: 0px;
    height: 0px;
    border-top: 0px solid #FE8D68;
    border-right: 44.6px solid #FE8D68;
    border-bottom: 44.6px solid transparent;
    border-left: 30px solid transparent;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  /* 旋转文字 */
  .laberText {
    width: 33px;
    height: 47px;
    transform: rotate(45deg);
    font-size: 12px;
    color: #ffffff;
    text-align: center;
  }
  .rentPriceA {
    color: rgba(252, 213, 204, 1);
    font-weight: 500;
  }
  .small_font {
    font-size: 12px !important;
  }

  .floatR {
    float: right !important;
  }
  .total_color {
    color: rgba(255, 93, 59, 1) !important;
  }

  /*免租期样式*/
  .noFreepanel{
    width: 92%;
    margin: 15px auto;
    background-color: rgb(255, 255, 255);
    border-radius: @redius;
    position: relative;
    font-size: 15px;
    text-align: left;
    overflow: hidden;
    //padding: 15px;
    box-sizing: border-box;
  }
  .freeTime {
    width: 345px;
    //margin: 15px 15px 15px 15px;
    height: auto;
    border-radius: 8px;
    background-color: white;
    overflow: hidden;
  }

  .totalTime {
    width: 345px;
    margin: 0 15px 15px 15px;
    height: 45px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .freeOne {
    height: auto;
    width: 100%;
    border-bottom: 1px solid #f8f8f8;
    overflow: hidden;
  }

  .freeOne-title {
    margin: 5px 0 5px 20px;
    color: red;
    font-weight: bold;
    font-size: 15px;
  }

  .freeOne-title span {
    color: black;
    font-weight: normal;
  }

  .freeOne-time {
    margin: 5px 0 5px 20px;
    color: black;
    font-weight: bold;
    font-size: 15px;
  }

  .freeOne-time span {
    color: black;
    font-weight: normal;
  }

  .totalTitle {
    margin-left: 20px;
    font-weight: bold;
    font-size: 15px;
    height: 20px;
    line-height: 22px;
    width: fit-content;
  }

  .totalLine {
    width: 1px;
    height: 15px;
    margin: 0 8px;
    background-color: gray;
  }

  .totalDays {
    width: 120px;
    height: 20px;
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    line-height: 22px;
    color: red;
  }

  .totalDesc {
    color: gray;
    text-align: left;
    font-size: 15px;
    height: 20px;
    line-height: 22px;
    width: fit-content;
    margin-left: 100px;
  }


  // 时间线
  .approve {
    margin-top: 20px;

    .time_line {
      .item {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .left {
          width: 20px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 15px;
          /*margin-right: 5px;*/

          .line {
            width: 1px;
            background-color: rgb(255, 75, 39);
            position: absolute;

            &.line_top {
              top: 0px;
              height: ceil(50%-8px);

              &.first_top {
                background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              }
            }

            &.line_bottom {
              bottom: 0;
              height: ceil(50%+10px);
            }
          }

          .point {
            position: relative;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            border: 1px solid rgb(255, 75, 39);
            transform: translateY(-50%);
            z-index: 99;
            background-color: rgb(255, 255, 255);

            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: rgb(255, 75, 39);
              width: 5px;
              height: 5px;
              border-radius: 50%;
            }
          }


        }

        .right {
          width: 100%;
          padding-bottom: 20px;

          .approve_panel {
            margin: 0 15px;
            border-radius: @redius;
            background-color: rgb(255, 255, 255);
            padding: 10px;

            p {
              padding-top: 10px;
              font-weight: bolder;

              &:last-of-type {
                padding-bottom: 10px;
              }

              span {
                font-weight: normal;
                color: rgb(82, 82, 82);
              }
            }
          }
        }
      }

    }
  }

}

</style>